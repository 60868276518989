import React, { useEffect, useRef } from 'react'
import { viewer } from './Viewer/ViewerService';
import { ChannelType, WebR } from 'webr';
import { DEMArrayAtom } from './Atoms/atoms';
import { useAtom } from 'jotai';


const webR = new WebR({ interactive: false,
  channelType: ChannelType.PostMessage,
 });
 





export default function PotreeViewer() {
  const [demArray, setDemArray]: any = useAtom(DEMArrayAtom);
  let viewerDiv = useRef<HTMLInputElement>(null);
  let demText = useRef<HTMLParagraphElement>(null);

  const GeoTIFFToPLY = async (url: string) => {
    await webR.init();
    await webR.installPackages([ 'terra' ]);
    await webR.evalR(`
  
      process_dem_file <- function(dem_file_path) {
        # Open the DEM raster file
        dem_raster <- rast(dem_file_path)
  
        # Convert the raster to a matrix
        dem_as_array <- as.matrix(dem_raster, wide = TRUE)
        return(dem_file_path)
      }
    `);
  }

  useEffect( () => {
    (async ()=>{
      const output:any = await GeoTIFFToPLY('c:/Users/jphar/OneDrive/Desktop/React Projects/GMRTv4_2_1_20240705topo.tif');
      var dem = document.getElementById('dem');
      dem?.replaceChildren(output);
      console.log(output);
      setDemArray(output);
    })();



    viewer.initialize(viewerDiv.current as HTMLElement);

    viewer
      .load(
        "cloud.js",
        "https://cdn.rawgit.com/potree/potree/develop/pointclouds/lion_takanawa/"
      )
      .then((pco:any) => {
        // Make the lion shows up at the center of the screen.
        pco.translateX(-1);
        pco.rotateX(-Math.PI / 2);

        // The point cloud octree already comes with a material which
        // can be customized directly. Here we just set the size of the
        // points.
        pco.material.size = 1.0;
      })
      .catch(err => console.error(err));
    return () => viewer.destroy();
  }, [])
  return (
    <div>
      <div id="target" ref={viewerDiv}>
      </div>
      <p id="dem" ref={demText}>Loading...</p>
    </div>
  )
}

