import logo from './logo.svg';
import './App.css';
import PotreeViewer from './Components/PotreeViewer';

function App() {


  return (
	<PotreeViewer></PotreeViewer>
  );
}

export default App;